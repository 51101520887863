import Grid from '@mui/material/Grid';
import Image from 'next/image';
import { memo, FunctionComponent } from 'react';

import boost from '../../../public/img/boost.webp';
import boostMobile from '../../../public/img/mobile/boost.webp';
import Button from '../../Buttons/Button';
import useBreakpoints from '../../Hooks/useBreakpoints';

const Boost: FunctionComponent = () => {
  const { isDesktop } = useBreakpoints();
  return (
    <Grid
      container
      item
      flexDirection={{ xs: 'column-reverse', lg: 'row' }}
      justifyContent={{ xs: 'center', lg: 'flex-end' }}
      alignContent={'center'}
      alignItems={'center'}
      boxShadow="0px 0px 11px rgba(136, 167, 188, 0.3)"
      borderRadius="6px"
      position="relative"
      padding={{ xs: '24px 16px', lg: '48px 71px' }}
      margin={{ xs: '48px 0px 91px', lg: '132px 0px' }}
      height={{ xs: '684px', lg: '391px' }}
      lg={12}
      gap={'36px'}
      wrap={'nowrap'}
    >
      <Grid
        item
        position={'absolute'}
        left={{ lg: '0px' }}
        top={{ xs: '445px', lg: '-88px' }}
        xs={12}
      >
        <Image
          src={isDesktop ? boost.src : boostMobile.src}
          alt="boost"
          width={isDesktop ? 620 : 312}
          height={isDesktop ? 597 : 300}
        />
      </Grid>
      <Grid
        container
        item
        direction="column"
        justifyContent={{ xs: 'flex-start', lg: 'flex-end' }}
        alignContent={{ xs: 'flex-start', lg: 'flex-end' }}
        alignItems="center"
        xs={12}
        lg={6}
        gap={'16px'}
      >
        <Grid item className="barlow--size32 bold">
          A Devyx não oferece <br />
          apenas tecnologia{' '}
          <span style={{ color: 'var(--primary-green)' }}>_</span>
        </Grid>
        <Grid item className="open-sans--size18">
          Garante <b>suporte</b> e <b>parceria</b> para você atingir o sucesso.
          <br />E <b>evoluir</b> com um mercado que nunca para.
        </Grid>
        <Grid item className="barlow--size18 bold">
          Duvida disso?{' '}
          <span style={{ color: 'var(--primary-blue)' }}>
            Bem, a gente acredita em você.
          </span>
        </Grid>
        <Grid
          item
          style={{
            margin: isDesktop ? '16px 0px 0px 0px' : '8px 0px 0px 0px',
          }}
          width={{ xs: '100%', lg: '256px' }}
        >
          <Button href="/business-boost">Conheça Business Boost</Button>
        </Grid>
        <Grid
          item
          className="barlow--size18"
          style={{ color: 'var(--default-black)' }}
          xs={'auto'}
        >
          Solução exclusiva para clientes Devyx
        </Grid>
      </Grid>
    </Grid>
  );
};

export default memo(Boost);
